import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Flex,
  Avatar
} from '@chakra-ui/react';
import moment from 'moment';


import { ReactComponent as InfoIcon } from '../../images/tell_me_more.svg';
import { ReactComponent as PlusIcon } from '../../images/add.svg'
import { ReactComponent as CategoryIcon } from '../../images/metadata_icons/category.svg';

import './index.scss';

const TriagePage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [triageBuckets, setTriageBuckets] = useState([]);

  useEffect(() => {
    fetchTriageBuckets();
  }, []);

  const fetchTriageBuckets = async () => {
    const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/list_triage_buckets`;
    const response = await axios.get(url);
    setTriageBuckets(response.data);
  };

  const handleAddTriageConfig = (e) => {
    e.preventDefault();
    // Add new triage config logic here
    onClose();
  };

  return (
    <Container maxW="container.xl" py={8}>
      <HStack justify="space-between" mb={8}>
        <Heading size="lg" fontFamily={'Garnett'} fontWeight={'600'}>Dispute Triage</Heading>
        <Button variant={'secondary'} onClick={() => navigate('/triage/new')} leftIcon={<PlusIcon />}>
          New Triage Bucket
        </Button>
      </HStack>

      <VStack spacing={6} align="stretch">
        {triageBuckets.map((bucket) => (
          <Card variant={'default'} key={bucket.id}>
            <CardHeader>
              <HStack justify="space-between">
                <Heading size="md">{bucket.label}</Heading>
              </HStack>
            </CardHeader>
            <CardBody>
              <Flex>
                <Box borderRight="1px" borderColor="gray.200" pr={4}>
                  <VStack align="stretch" spacing={8}>
                    <Box>
                      <Text fontSize="2xl">{bucket.cases_today}</Text>
                      <Text fontWeight="bold">Today</Text>
                    </Box>
                    <Box>
                      <Text fontSize="2xl">{bucket.cases_this_week}</Text>
                      <Text fontWeight="bold">This Week</Text>
                    </Box>
                    <Box>
                      <Text fontSize="2xl">{bucket.total_cases}</Text>
                      <Text fontWeight="bold">Total Cases</Text>
                    </Box>
                  </VStack>
                </Box>
                <Box flex={1} pl={4} overflowX="auto">
                  <HStack justify="space-between" mb={4}>
                    <Text fontWeight="bold">Recent Cases</Text>
                    <Button size="sm" variant="outline">View All</Button>
                  </HStack>
                  <HStack
                    spacing={4}
                    overflowX="auto"
                    pb={4}
                    alignItems="stretch"
                  >
                    {bucket.matching_cases.map(caseItem => (
                      <Box
                        key={caseItem.id}
                        p={4}
                        w="300px"
                        borderWidth={1}
                        borderRadius="md"
                        flexShrink={0}
                        bg="white"
                        display="flex"
                        flexDirection="column"
                      >
                        <VStack align="stretch" spacing={1} flex={1}>
                          <Box minH="3rem">
                            <Text color={'#000000'} fontWeight={'600'} fontSize={'1.2rem'}>{caseItem.name}</Text>
                          </Box>
                          <HStack>
                            <Badge mt={1} variant={'case_status'}>
                              <HStack>
                                <InfoIcon fill="#000000" />
                                <Text fontSize={'0.85rem'} fontWeight={'bold'}>
                                  {caseItem.status?.text}
                                </Text>
                              </HStack>
                            </Badge>
                          </HStack>
                          <VStack align="stretch" spacing={1} mt={4}>
                            <HStack justify="start">
                              <Box border={'1px solid #d9d9d9'} borderRadius={'4px'} p={1}>
                                <CategoryIcon height={16} width={16} fill={'#00B870'} />
                              </Box>
                              <Text fontSize="md">{caseItem.category}</Text>
                            </HStack>
                          </VStack>
                          <Box flex={1} />
                          <VStack spacing={4} mt="auto">
                            <HStack align="center" justify="space-between" width="100%">
                              <HStack>
                                <Avatar size="xs" src={caseItem.assignee?.profile_picture_url || ''} />
                                <Text fontWeight={'600'}>{caseItem.assignee?.name || 'Unassigned'}</Text>
                              </HStack>
                              <Text fontSize="sm">{moment(caseItem.created_at).fromNow()}</Text>
                            </HStack>
                            <Button onClick={() => navigate(`/case/${caseItem.id}`)} variant={'default_muted'} size="sm" width="100%">
                              Go to Case
                            </Button>
                          </VStack>
                        </VStack>
                      </Box>
                    ))}
                  </HStack>
                </Box>
              </Flex>
            </CardBody>
          </Card>
        ))}
      </VStack>
    </Container>
  );
};

export default TriagePage;
