import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Text, Button, VStack, HStack, Flex, Card, Tooltip, IconButton, Avatar, Menu, MenuButton, MenuList, MenuItem, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper } from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';

import { renderAsync } from 'docx-preview';


import { ReactComponent as LabelIcon } from '../../images/label.svg';
import { ReactComponent as DownloadFileIcon } from '../../images/download.svg';
import { ReactComponent as OptionsIcon } from '../../images/options.svg';
import { ReactComponent as NewVersionIcon } from '../../images/new-version.svg';
import { ReactComponent as DeleteIcon } from '../../images/delete.svg';

import DeleteCaseFileAlert from './DeleteCaseFileAlert';
import PreviewCaseFileModal from './PreviewCaseFileModal';
import CaseFileUploadWizard from './CaseFileUploadWizard';



const CaseFilesTab = ({ caseName, caseID }) => {
    const [caseFiles, setCaseFiles] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileTag, setFileTag] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const previewRef = useRef(null);
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [fileParty, setFileParty] = useState([]);
    const [versionName, setVersionName] = useState('');
    const [shouldShowWizard, setShouldShowWizard] = useState(false);
    const [processedTagsFromPleading, setProcessedTagsFromPleading] = useState({
        claimant_tags: [],
        respondent_tags: []
    });
    const cancelRef = useRef();

    const onDrop = (acceptedFiles) => {
        setSelectedFile(acceptedFiles[0]);
    };

    const handleUpload = () => {
        setIsModalOpen(true);
        setSelectedFileId(null);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedFile(null);
        setFileTag('');
        setSelectedFileId(null);
        setVersionName('');
    };

    useEffect(() => {
        fetchCaseFiles();
    }, []);


    const fetchCaseFiles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/list_casefiles?case_id=${caseID}`);
            console.log('SETTING CASE FILES', response.data.files)
            setCaseFiles(response.data.files);
            setProcessedTagsFromPleading({
                claimant_tags: response.data.claimant_pleading_tags,
                respondent_tags: response.data.respondent_pleading_tags
            });
        } catch (error) {
            console.error('Error fetching case files:', error);
        }
    };

    useEffect(() => {
        if (selectedFile && previewRef.current) {
            if (selectedFile.type === 'application/pdf') {
                // PDF preview is handled by react-pdf
            } else if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                renderAsync(selectedFile, previewRef.current);
            }
        }
    }, [selectedFile]);

    const fetchCaseFileUrl = async (fileID) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/get_casefile_url?file_id=${fileID}&case_id=${caseID}`);
            return response.data.presigned_url;
        } catch (error) {
            console.error('Error fetching case file URL:', error);
        }
    };

    const handleDownload = async (fileID) => {
        const url = await fetchCaseFileUrl(fileID);
        window.open(url, '_blank');
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleFileUpload = async () => {
        if (!selectedFile) return;
        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('case_id', caseID);
        formData.append('party', fileParty);
        if (fileTag) formData.append('tag', fileTag);
        if (selectedFileId) {
            formData.append('file_id', selectedFileId);
            if (versionName) formData.append('version_name', versionName);
        }

        try {
            await axios.put(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/upload_casefile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // Handle successful upload (e.g., show success message, refresh file list)
            handleCloseModal();
            fetchCaseFiles();
            setIsUploading(false);
        } catch (error) {
            // Handle error (e.g., show error message)
            console.error('Error uploading file:', error);
            setIsUploading(false);
        }
    };

    const handleDeleteFile = async (fileID) => {
        try {
            await axios.delete(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/delete_casefile?file_id=${fileID}&case_id=${caseID}`);
            fetchCaseFiles();
            setIsDeleteAlertOpen(false);
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };

    const handleUploadNewVersion = async (fileID) => {
        setSelectedFileId(fileID);
        setIsModalOpen(true);
    };

    const openDeleteConfirmation = (file) => {
        setFileToDelete(file);
        setIsDeleteAlertOpen(true);
    };

    return (
        <Box>
            {(shouldShowWizard || (!caseFiles || (caseFiles && caseFiles.length === 0))) ? (
                <>
                {caseFiles && caseFiles.length > 1 && <Button onClick={() => setShouldShowWizard(false)} variant={'outline'}>Cancel</Button>}
                <CaseFileUploadWizard
                caseID={caseID} 
                onFinish={() => setShouldShowWizard(false)}
                processedTagsFromPleading={processedTagsFromPleading}
                caseFiles={caseFiles}
                />
                </>
            ) : (<>
            
            <Flex mb={4}>
                    <Button onClick={() => setShouldShowWizard(true)} variant={'secondary'}>Add Files</Button>
                </Flex>
                {caseFiles && caseFiles.map((file, index) => (
                    <Card key={index} shadow={'none'} my={2} px={6} py={4} border={'1px solid'} borderColor={'gray.200'}>
                        <Flex justifyContent={'space-between'} align={'center'}>
                            <HStack align="center" spacing={1} width={'calc(100% - 90px)'} justifyContent={'space-between'}>
                                <VStack align="start" spacing={2}>
                                    <Tooltip label={"File tag"} hasArrow>
                                        <HStack spacing={2} bg={'gray.200'} py={1} px={3} borderRadius={'md'}>
                                            <LabelIcon height={'20px'} width={'20px'} fill='#2d3748' />
                                            <Text color={'gray.700'} fontSize={'0.95rem'} fontWeight={'600'}>
                                                {file.tag}
                                            </Text>
                                        </HStack>
                                    </Tooltip>
                                    {file.version_count === 1 ? <Text fontSize={'0.9rem'} fontWeight={'600'} color={'#4b4b4b'}>
                                        {file.original_filename}
                                    </Text> : <Text fontSize={'0.9rem'} fontWeight={'600'} color={'#4b4b4b'}>
                                        {file.original_filename} + {file.version_count - 1} other {file.version_count === 2 ? 'version' : 'versions'}
                                    </Text>}
                                </VStack>
                                <HStack minW={'230px'} spacing={2} align="center" justifyContent="flex-end">
                                    <HStack>
                                        <Avatar size="sm" src={file.uploaded_by.profile_picture_url} />
                                        <Text fontSize={'sm'} fontWeight="bold" color={'#4b4b4b'}>{file.uploaded_by.full_name}</Text>
                                    </HStack>
                                    <HStack>

                                        <Text fontSize={'1.2rem'}>·</Text>
                                        <Text fontSize="sm" color="gray.500">
                                            {moment(file.created_at * 1000).fromNow()}
                                        </Text>
                                    </HStack>

                                </HStack>
                            </HStack>
                            <Menu variant={'default'}>
                                <MenuButton
                                    as={IconButton}
                                    aria-label='Options'
                                    icon={<OptionsIcon style={{ transform: 'rotate(90deg)' }} fill={'#a8a8a8'} />}
                                    variant='ghost'
                                />
                                <MenuList>
                                    <MenuItem onClick={() => handleDownload(file.file_id)}>
                                        <DownloadFileIcon fill={'var(--chakra-colors-gray-500)'} />
                                        Download file
                                    </MenuItem>
                                    <MenuItem onClick={() => handleUploadNewVersion(file.file_id)}>
                                        <NewVersionIcon fill={'var(--chakra-colors-gray-500)'} />
                                        Upload new version
                                    </MenuItem>
                                    <MenuItem onClick={() => openDeleteConfirmation(file)} color={'red.500'}>
                                        <DeleteIcon fill='var(--chakra-colors-red-500)' />
                                        Delete file
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Flex>
                    </Card>
                ))}
            </>)}

            <PreviewCaseFileModal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                selectedFile={selectedFile}
                selectedFileId={selectedFileId}
                caseName={caseName}
                setFileParty={setFileParty}
                setFileTag={setFileTag}
                versionName={versionName}
                setVersionName={setVersionName}
                fileTag={fileTag}
                fileParty={fileParty}
                handleFileUpload={handleFileUpload}
                isUploading={isUploading}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                numPages={numPages}
                previewRef={previewRef}
                onDrop={onDrop}
            />
            <DeleteCaseFileAlert
                    isDeleteAlertOpen={isDeleteAlertOpen}
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                    handleDeleteFile={handleDeleteFile}
                    fileToDelete={fileToDelete}
                    cancelRef={cancelRef}
                />
        </Box>
    );
};

export default CaseFilesTab;