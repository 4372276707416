import React, { useState, useEffect } from "react";

import { useDropzone } from 'react-dropzone';
import { renderAsync } from 'docx-preview';
import { Document, Page, pdfjs } from 'react-pdf';

import { Card, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Box, VStack, Text, Button, HStack, Input, InputGroup, InputLeftElement, Divider, Select, FormControl, FormLabel } from "@chakra-ui/react";

import { ReactComponent as UploadIcon } from '../../images/upload.svg';
import DropdownWithCreate from '../../components/DropdownWithCreate';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs`;

const PreviewCaseFileModal = ({
    isModalOpen,
    handleCloseModal,
    selectedFile,
    caseName,
    handleFileUpload,
    isUploading,
    onDocumentLoadSuccess,
    numPages,
    previewRef,
    onDrop,
    onUploadSuccess,
    currentStep,
    shouldShowTags,
    tagList,
    usedTags
}) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const [selectedTag, setSelectedTag] = useState('');
    const [availableTags, setAvailableTags] = useState(tagList);

    useEffect(() => {
        if(tagList) {
            setAvailableTags(tagList.filter(tag => !usedTags.has(tag)));
        }
    }, [usedTags, tagList]);

    useEffect(() => {
        setSelectedTag('');
    }, [selectedFile]);

    const handleUpload = async () => {
        await handleFileUpload(selectedTag);
        onUploadSuccess(selectedFile);
    };

    return (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} closeOnOverlayClick={false} closeOnEsc={false} size="2xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload File - {caseName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody position="relative">
                    {selectedFile && <VStack spacing={4} height="80vh" maxHeight={'800px'}>
                        <Box width="100%" height="100%" overflowY="auto" borderRadius={'md'}>
                            {selectedFile.type === 'application/pdf' ? (
                                <Document
                                    file={selectedFile}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    key={selectedFile.name}
                                >
                                    {Array.from(new Array(numPages), (el, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                        />
                                    ))}
                                </Document>
                            ) : (
                                <div className='docx-preview-upload-preview' ref={previewRef} style={{ width: '100%', height: '100%', marginBottom: '560px' }}></div>
                            )}
                        </Box>
                        <Card
                            position="absolute"
                            bottom="4"
                            left="50%"
                            transform="translateX(-50%)"
                            bg="white"
                            boxShadow="lg"
                            borderRadius="md"
                            p={4}
                            width="90%"
                            zIndex={99}
                            border="1px solid"
                            borderColor="gray.200"
                            backgroundColor="green.50"
                        >
                            <VStack w={'100%'} alignItems={'center'} spacing={2}>
                                <Text fontWeight="bold">
                                    {selectedFile ? selectedFile.name : ''}
                                </Text>
                                <Divider mb={1} variant={'default'} />
                                {shouldShowTags && (
                                    <DropdownWithCreate
                                        options={availableTags.map(tag => ({ id: tag, label: tag }))}
                                        onChange={(tagId) => setSelectedTag(tagId)}
                                        selectedValue={selectedTag}
                                        placeholderText="Search or create new tag..."
                                        onCreateNew={(newTagName) => {
                                            setSelectedTag(newTagName);
                                        }}
                                    />
                                )}
                                <HStack spacing={4} w="100%" justifyContent="center">
                                    <Button
                                        isLoading={isUploading}
                                        variant={'secondary'}
                                        onClick={handleUpload}
                                        isDisabled={shouldShowTags && !selectedTag}
                                    >
                                        Upload
                                    </Button>
                                </HStack>
                            </VStack>
                        </Card>
                    </VStack>}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default PreviewCaseFileModal;