import React from "react"

const AllCasesPageIcon = ({ isActive }) => {
    const fillColour = isActive ? '#004732' : '#979797'
    return (<svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill={fillColour}><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg>
        
    )
}

export default AllCasesPageIcon;
