import React, { useState, useEffect } from "react";
import axios from "axios";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Box,
    Text,
    Button,
    VStack,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Flex
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const ChangeRoleModal = ({ isOpen, onClose, handleRoleChange, member }) => {
    const [allRoles, setAllRoles] = useState([])
    const [selectedRole, setSelectedRole] = useState(member?.role?.id || '');
    const [isLoading, setIsLoading] = useState(false);

    const fetchRoles = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/roles`)
        setAllRoles(response.data.roles)
    }

    useEffect(() => {
        fetchRoles()
    }, [])

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            await axios.put(`${process.env.REACT_APP_BASE_PATH}/change_role`, {
                user_id: member.user_id,
                role: selectedRole
            });
            handleRoleChange(member.user_id, selectedRole);
            onClose();
        } catch (error) {
            console.error('Error changing role:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const filteredRoles = allRoles.filter(role => role.id !== member?.role?.id);

    return (
        <Modal
            size={'xl'}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader pb={2}>
                    Change Member Role
                </ModalHeader>

                <ModalBody>
                    <Box>
                        <Text fontSize="md" color="gray.600" mb={4}>
                            Changing the role will affect <b>{member?.full_name}'s </b>permissions
                        </Text>
                        <Flex align={'center'} justify={'center'} border={'1px solid #cfcfcf'} p={4} borderRadius={4}>
                            <HStack width={'100%'} justify={'space-between'} spacing={3}>
                                <VStack align={'start'}>
                                    <Text>Current Role</Text>
                                    <Text color={'#000'}>{member?.role?.name}</Text>
                                </VStack>
                                <Text>⟶</Text>
                                <VStack align={'start'}>
                                    <Text>New Role</Text>
                                    <Menu variant={'default'}>
                                        <MenuButton as={Button} fullWidth variant={'default_muted'} fontFamily={'Inter'} fontWeight={500} rightIcon={<ChevronDownIcon />}>
                                            {filteredRoles.find(role => role.id === selectedRole)?.name || 'Select Role'}
                                        </MenuButton>
                                        <MenuList>
                                            {filteredRoles.map((role) => (
                                                <MenuItem key={role.id} onClick={() => setSelectedRole(role.id)}>
                                                    {role.name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                </VStack>
                            </HStack>
                        </Flex>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
                        Cancel
                    </Button>
                    <Button 
                        variant={'default'} 
                        onClick={handleSubmit}
                        isLoading={isLoading}
                        loadingText="Changing Role"
                        isDisabled={!selectedRole}
                    >
                        Change Role
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ChangeRoleModal;