import React, { useEffect, useRef, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Divider,
    Container,
    Flex,
    Box,
    Text,
    Card,
    VStack,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    useToast,
    Editable,
    EditablePreview,
    EditableInput,
    EditableTextarea
} from '@chakra-ui/react';
import { ReactComponent as SearchIcon } from '../../images/search.svg';
import axios from 'axios';
import { renderAsync } from 'docx-preview';

import './index.scss';

import { highlightAndScrollToText, removeAllSpaces } from '../../helpers/DocHighlightHelper'

const DocumentPreview = ({ docPreview, initialText }) => {
    const containerRef = useRef(null);
    const [isRendered, setIsRendered] = useState(false);

    useEffect(() => {
        if (!containerRef.current || !docPreview) return;

        const renderDocument = async () => {
            try {
                await renderAsync(docPreview, containerRef.current);
                setIsRendered(true);

                if (initialText) {
                    const processedText = removeAllSpaces(initialText);
                    setTimeout(() => {
                        highlightAndScrollToText(containerRef.current, processedText);
                    }, 500);
                }
            } catch (err) {
                console.error(err);
            }
        };

        renderDocument();
    }, [docPreview]);

    useEffect(() => {
        if (isRendered && initialText) {
            const processedText = removeAllSpaces(initialText);
            setTimeout(() => {
                highlightAndScrollToText(containerRef.current, processedText);
            }, 100);
        }
    }, [initialText, isRendered]);

    return (
        <Box
            ref={containerRef}
            height="100%"
            overflowY="auto"
            className='verify-preview-container'
        />
    );
};

const VerifyMetadataModal = ({ isOpen, onClose, docPreview, metadataPayload = {}, onMetadataVerified }) => {
    const [isRendered, setIsRendered] = useState(false);
    const [newMetadataValue, setNewMetadataValue] = useState('')
    const [isUpdating, setIsUpdating] = useState(false)
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!isOpen) {
            setIsRendered(false);
            setIsLoading(true);
            setError(null);
        }
    }, [isOpen]);

    useEffect(() => {
        if (metadataPayload?.metadata) {
            setNewMetadataValue(metadataPayload.metadata.value || '');
        }
    }, [metadataPayload]);

    const handleUpdateMetadata = async () => {
        setIsUpdating(true)
        try {
            const payload = {
                case_id: metadataPayload.caseID,
                field_id: metadataPayload.metadata.id,
                id: metadataPayload.metadata.id,
                type: metadataPayload.metadata.type,
                is_corrected: metadataPayload.mode === 'reject',
                value: metadataPayload.mode === 'reject' ? newMetadataValue : metadataPayload.metadata.value
            }
            await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/verify_field`, payload);
            
            onMetadataVerified(metadataPayload.metadata.id);
            
            toast({
                title: "Metadata verified",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            onClose();
        } catch (error) {
            console.error('Error verifying metadata:', error);
            toast({
                title: "Error verifying metadata",
                description: error.response?.data?.error || error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdating(false)
        }
    }

    const goToDoc = (doc_text) => {
        if (!doc_text) return;

        const processedText = removeAllSpaces(doc_text);
        const container = document.querySelector('.verify-preview-container');
        if (container) {
            setTimeout(() => {
                highlightAndScrollToText(container, processedText);
            }, 100);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent w="100%" maxW={'80%'} maxH="90vh">
                <ModalHeader>{metadataPayload?.mode === 'reject' ? 'Manually Fill Metadata' : 'Accept Metadata'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Container 
                    // maxW='container.xl'
                    maxW={'100%'}
                    >
                    <Flex gap={8} height="70vh">
                        <Box width="50%" overflowY="auto">
                            <VStack align={'flex-start'} spacing={4}>
                                <Text>Source Sentences</Text>
                                <VStack align="stretch" width="100%" spacing={2}>
                                    {metadataPayload?.metadata?.source_sentences?.map((sentence, index) =>
                                        <Card onClick={() => goToDoc(sentence)} key={index} variant={'outline'} p={3} width={'100%'}>
                                            <Text fontSize="sm">{sentence}</Text>
                                        </Card>)}
                                </VStack>

                                <Card variant={'outline'} p={[2, 4]} width={'100%'}>
                                    <HStack>
                                        {metadataPayload?.fieldIcon && React.createElement(metadataPayload.fieldIcon, { width: 24, height: 24, fill: '#00B870' })}
                                        <Text fontWeight="bold" fontSize="lg">{metadataPayload?.metadata?.field}</Text>
                                    </HStack>

                                    {metadataPayload?.mode === 'reject' ? (
                                        <>
                                            <Box p={4} borderRadius="md">
                                                <HStack spacing={6} align="flex-start">
                                                    <Box flex={1}>
                                                        <Text fontSize="sm" color="gray.500" mb={1}>Current Value</Text>
                                                        <Text fontSize="md" color="gray.400" fontStyle="italic">
                                                            {metadataPayload?.metadata?.value}
                                                        </Text>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Text fontSize="sm" color="gray.500" mb={1}>New Value</Text>
                                                        <Editable
                                                            value={newMetadataValue}
                                                            onChange={(value) => setNewMetadataValue(value)}
                                                            placeholder="Enter new value..."
                                                            fontSize="md"
                                                            width="100%"
                                                        >
                                                            <EditablePreview
                                                                py={2}
                                                                px={3}
                                                                bg="white"
                                                                borderRadius="md"
                                                                borderWidth={1}
                                                                borderColor="gray.200"
                                                                _hover={{
                                                                    bg: "white",
                                                                    borderColor: "gray.300"
                                                                }}
                                                            />
                                                            <EditableTextarea
                                                                py={2}
                                                                px={3}
                                                                borderRadius="md"
                                                            />
                                                        </Editable>
                                                    </Box>
                                                </HStack>
                                                <Button
                                                    mt={4}
                                                    isDisabled={!newMetadataValue}
                                                    isLoading={isUpdating}
                                                    variant={'default'}
                                                    size={'md'}
                                                    width="100%"
                                                    onClick={handleUpdateMetadata}
                                                >
                                                    Save New Value
                                                </Button>
                                            </Box>
                                        </>
                                    ) : (
                                        <Box p={4} borderRadius="md">
                                            <HStack spacing={4} mb={4}>
                                                <Box flex={1}>
                                                    <Text fontSize="md" color="gray.700">
                                                        {metadataPayload?.metadata?.value}
                                                    </Text>
                                                </Box>
                                            </HStack>
                                            <Button
                                                isLoading={isUpdating}
                                                variant={'default'}
                                                size={'md'}
                                                width="100%"
                                                onClick={handleUpdateMetadata}
                                                colorScheme="green"
                                            >
                                                Confirm and Accept
                                            </Button>
                                        </Box>
                                    )}
                                </Card>
                            </VStack>
                        </Box>
                        <Box width={'50%'} height="100%" overflowY="hidden">
                            <DocumentPreview
                                docPreview={docPreview}
                                initialText={metadataPayload?.metadata?.source_sentences?.[0]}
                            />
                        </Box>
                    </Flex>
                    </Container>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default VerifyMetadataModal;