import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, VStack, Text, Avatar, useOutsideClick, Input, HStack } from '@chakra-ui/react';
import { ReactComponent as CancelFilled } from '../../images/cancel_filled.svg';
import { ReactComponent as ChevronRight } from '../../images/chevron_right.svg';
import { motion } from 'framer-motion';

const SelectDropdown = ({ options, onChange, selectedValue, placeholderText = "Click to select", isMultiSelect = false, shouldShowAssignee = true, shouldShowSearch = true, Icon, isClearable = true, shouldShowChevron = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const dropdownRef = useRef(null);


  useEffect(() => {
    // Assign a default value if selectedValue is present
    if (selectedValue) {
      setSelectedOptions([selectedValue]);
    }
  }, [selectedValue]);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const handleSelect = (account) => {
    if (isMultiSelect) {
      const updatedSelection = selectedOptions.some(selected => selected.id === account.id)
        ? selectedOptions.filter(selected => selected.id !== account.id)
        : [...selectedOptions, account];
      setSelectedOptions(updatedSelection);
      onChange(updatedSelection.map(acc => acc.id));
    } else {
      setSelectedOptions([account]);
      setIsOpen(false);
      onChange(account.id);
    }
  };

  const handleClear = () => {
    setSelectedOptions([]);
    onChange(isMultiSelect ? [] : null);
  };

  const filteredAccounts = options.filter(account =>
    account.option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderSelectedContent = () => {
    if (selectedOptions.length === 0) return placeholderText;
    if (selectedOptions.length === 1) {
      return (
        <HStack spacing={1}>
          {shouldShowAssignee && selectedOptions.length > 0 && (
            <Avatar
              size={'xs'}
              name={selectedOptions[0].option}
              src={selectedOptions[0].profilePicture}
              mr={2}
            />
          )}
          <Text color={'#515151'} fontWeight={'600'}>{selectedOptions[0].option}</Text>
        </HStack>
      );
    }
    return (
      <Text color={'#515151'} fontFamily={'Garnett'} fontWeight={'600'}>
        {selectedOptions[0].option} +{selectedOptions.length - 1} more
      </Text>
    );
  };

  return (
    <Box position="relative" ref={dropdownRef}>
      <Button
        variant={'dropdown_button'}
        onClick={() => setIsOpen(!isOpen)}
        _hover={{ bg: 'gray.50' }}
        title={selectedOptions.length > 1 ? selectedOptions.map(acc => acc.option).join(', ') : undefined}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        position="relative"
        overflow="hidden"
      >
        <HStack>

          {/* Hide the icon when we're showing an assignee's profile picture */}
          {(!(shouldShowAssignee && selectedOptions.length) && Icon) && <Box height={'24px'} width={'24px'} mr={1}>
            <Icon fill={'#515151'} height={'24px'} width={'24px'} />
          </Box>}
          <Box position="relative" width="100%">
            {renderSelectedContent()}
          </Box>
          {isClearable && selectedOptions.length > 0 && <Box
            position="absolute"
            right={0}
            top={0}
            bottom={0}
            width="60px"
            opacity={0}
            transition="opacity 0.2s ease-in-out"
            background="linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1) 70%)"
            pointerEvents="none"
            sx={{
              '.chakra-button:hover &': {
                opacity: 1,
              },
            }}
          />}
          {isClearable && selectedOptions.length > 0 && (
            <Box
              as={motion.div}
              position="absolute"
              right={2}
              top="50%"
              transform="translateY(-50%)"
              initial={{ opacity: 0 }}
              animate={{ opacity: isHovered ? 1 : 0 }}
              transition={{ duration: 0.1 }}
              onClick={(e) => {
                e.stopPropagation();
                handleClear();
              }}
              cursor="pointer"
              zIndex={2}
            >
              <CancelFilled fill="#a8a8a8" height="16px" width="16px" />
            </Box>
          )}
          {shouldShowChevron && (
            <Box
              as={motion.div}
              initial={{ rotate: 0 }}
              animate={{ rotate: isOpen ? 180 : 0 }}
              transition={{ duration: 0.2 }}
            >
              <ChevronRight style={{ transform: 'rotate(90deg)' }} fill="#a8a8a8" height="24px" width="24px" />
            </Box>
          )}
        </HStack>
      </Button>
      {isOpen && (
        <VStack
          position="absolute"
          top="100%"
          left={0}
          width="100%"
          maxHeight="400px"
          w={'250px'}
          overflowY="auto"
          bg="white"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          boxShadow="lg"
          zIndex={10}
          mt={1}
          spacing={0}
        >
          {shouldShowSearch && (
            <Input
              variant={'default'}
              p={2}
              placeholder="Search"
              autoFocus={true}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              m={2}
              width="90%"
            />
          )}
          {filteredAccounts.map((account) => (
            <Box
              key={account.id}
              width="100%"
              px={3}
              py={3}
              cursor="pointer"
              _hover={{ bg: 'gray.50' }}
              onClick={() => handleSelect(account)}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  {shouldShowAssignee && (
                    <Avatar
                      size={'xs'}
                      name={account.option}
                      src={account.profilePicture}
                      mr={2}
                    />
                  )}
                  <Text fontSize={'0.9rem'} color={'#515151'} fontFamily={'Garnett'} fontWeight={'600'}>{account.option}</Text>
                </Box>
                {isMultiSelect && selectedOptions.some(selected => selected.id === account.id) && (
                  <Box color="green.500">✓</Box>
                )}
              </Box>
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default SelectDropdown;
