import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Text, Button, VStack, HStack, Badge } from '@chakra-ui/react';
import { ReactComponent as InfoIcon } from '../../images/info.svg'
const CaseDeleteAlert = ({ isOpen, setIsOpen, handleAction, cancelRef, isDelete = false, caseDetails }) => {
    return (
        <AlertDialog
            size={'xl'}
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => setIsOpen(false)}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader pb={2} fontSize="lg" fontWeight="bold">
                        {isDelete ? 'Delete Case' : 'Archive Case'}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <Box>
                            <Box>
                                {isDelete ? (
                                    <>
                                        <Text color="red.500" fontWeight="bold" mb={2}>Warning: This action cannot be undone</Text>
                                        <Text>All case files, analysis and activity data will be permanently removed.</Text>
                                    </>
                                ) : (
                                    <>
                                        <Text mb={2}>Are you sure you want to archive this case?</Text>
                                        <Text fontSize="sm" color="gray.600">
                                            Archiving will move this case to the archives section. You can restore it later if needed.
                                        </Text>
                                    </>
                                )}
                            </Box>

                            <Box border={'1px solid'} borderColor={'gray.200'} borderRadius={'md'} p={4} mt={2}>
                                <VStack align={'flex-start'}>
                                    <Text color={'#000'} fontFamily={'Garnett Medium'}>
                                        {caseDetails.case_name}
                                    </Text>

                                    <Badge mt={2} variant={'case_status'}>
                                        <HStack>
                                            <InfoIcon fill="#000000" />
                                            <Text fontSize={'0.85rem'} fontWeight={'bold'}>
                                                {caseDetails.status}
                                            </Text>
                                        </HStack>
                                    </Badge>
                                </VStack>
                            </Box>
                        </Box>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme={isDelete ? "red" : "gray"}
                            onClick={handleAction}
                            ml={3}
                        >
                            {isDelete ? 'Delete Permanently' : 'Archive Case'}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default CaseDeleteAlert;