import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Text, Button, VStack, HStack } from '@chakra-ui/react';

import { ReactComponent as LabelIcon } from '../../images/label.svg';
const DeleteCaseFileAlert = ({ isDeleteAlertOpen, setIsDeleteAlertOpen, handleDeleteFile, fileToDelete, cancelRef }) => {
    return (
        <AlertDialog
        size={'xl'}
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteAlertOpen(false)}
    >
        <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader pb={2} fontSize="lg" fontWeight="bold">
                    Confirm File Deletion
                </AlertDialogHeader>

                <AlertDialogBody>
                    {fileToDelete && (
                        <Box>
                            <Text>Are you sure you want to delete this file {fileToDelete.version_count > 1 ? <b style={{ color: '#000' }}>and {fileToDelete.version_count - 1} other {fileToDelete.version_count === 2 ? 'version' : 'versions'}?</b> : "?"}</Text>
                            <Box border={'1px solid'} borderColor={'gray.200'} borderRadius={'md'} p={4} mt={2}>
                                <VStack alignItems={'flex-start'} spacing={2}>
                                    <HStack spacing={2} bg={'gray.200'} py={1} px={3} borderRadius={'md'}>
                                        <LabelIcon height={'20px'} width={'20px'} fill='#2d3748' />
                                        <Text color={'gray.700'} fontSize={'0.95rem'} fontWeight={'600'}>
                                            {fileToDelete.tag}
                                        </Text>
                                    </HStack>
                                    <Text fontSize={'0.9rem'} fontWeight={'600'} color={'#4b4b4b'}>
                                        {fileToDelete.original_filename} {fileToDelete.version_count > 1 && <> + {fileToDelete.version_count - 1} other {fileToDelete.version_count === 2 ? 'version' : 'versions'}</>}
                                    </Text>
                                </VStack>
                            </Box>
                        </Box>
                    )}
                </AlertDialogBody>

                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)}>
                        Cancel
                    </Button>
                    <Button colorScheme="red" onClick={() => handleDeleteFile(fileToDelete.file_id)} ml={3}>
                        Delete
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default DeleteCaseFileAlert;