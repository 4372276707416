import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import {
  Text,
  Box,
  Flex,
  Button,
  HStack,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Badge,
  Card,
  InputGroup,
  InputLeftElement,
  Divider,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow
} from '@chakra-ui/react';
import { DayPicker, getDefaultClassNames } from 'react-day-picker';
import "react-day-picker/style.css";

import statusLabels from '../../constants/status.js';

import SelectDropdown from '../../components/SelectDropdown/index.jsx';
import { AuthContext } from '../../helpers/AuthContext.js';
import { ReactComponent as AddIcon } from '../../images/add.svg';
import { ReactComponent as InfoIcon } from '../../images/info.svg';
import { ReactComponent as SortIcon } from '../../images/sort.svg';
import { ReactComponent as ProfileIcon } from '../../images/profile.svg';
import { ReactComponent as DateRange } from '../../images/date_range.svg';
import { ReactComponent as SearchIcon } from '../../images/search.svg';
import { ReactComponent as WarningIcon } from '../../images/warning.svg';
import './index.scss';

const AnalysisHistory = ({ globalState, setGlobalState }) => {
  const navigate = useNavigate();
  const defaultClassNames = getDefaultClassNames();

  const { isLoading } = useContext(AuthContext);
  const [pastAudits, setPastAudits] = useState([]);
  const [isNewCaseModalOpen, setIsNewCaseModalOpen] = useState(false);
  const [newCaseName, setNewCaseName] = useState('');
  const [isCreatingCase, setIsCreatingCase] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const toast = useToast();

  // New state for sort and filter
  const [sortOrder, setSortOrder] = useState('desc');
  const [filterOptions, setFilterOptions] = useState({
    created: { from: null, to: null },
    assignee: null,
    status: null
  });

  useEffect(() => {
    if (!isLoading) {
      fetchAnalysisHistory();
      fetchMembers();
    }
  }, [isLoading, sortOrder, filterOptions]);

  const fetchMembers = async () => {
    const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/list_assignees`, {
      params: {
        type: ['member']
      }
    });
    const transformedAssignees = response.data.members.map(member => ({
      id: member._id,
      option: member.full_name,
      profilePicture: member.profile_picture_url
    }));
    setAssignees(transformedAssignees);
  }

  const fetchAnalysisHistory = async () => {
    const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/case_list`;

    // Add one day to the end date to include the full end date
    let endDate = filterOptions.created.to;
    if (endDate) {
      endDate = new Date(endDate);
      endDate.setDate(endDate.getDate() + 1);
    }

    const requestBody = {
      sort: sortOrder,
      filter: {
        ...filterOptions,
        created: {
          from: filterOptions.created.from ? Math.floor(filterOptions.created.from.getTime() / 1000) : undefined,
          to: endDate ? Math.floor(endDate.getTime() / 1000) : undefined
        }
      }
    };
    try {
      const response = await axios.post(url, requestBody);
      setPastAudits(response.data);
    } catch (error) {
      console.error('Error fetching analysis history:', error);
      toast({
        title: "Error",
        description: "Failed to fetch analysis history. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const convertToRelativeTime = timestamp => {
    return moment(timestamp * 1000).fromNow();
  };

  const handleNavigateToAnalysis = async caseID => {
    navigate(`/case/${caseID}`);
  };

  const handleNewCase = () => {
    setIsNewCaseModalOpen(true);
  };

  const handleCreateNewCase = async () => {
    if (!newCaseName.trim()) {
      toast({
        title: "Error",
        description: "Please enter a case name",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsCreatingCase(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/create_case`, {
        case_name: newCaseName
      });

      if (response.data && response.data.case_id) {
        navigate(`/case/${response.data.case_id}`);
      } else {
        throw new Error('No case ID received from the server');
      }
    } catch (error) {
      console.error('Error creating new case:', error);
      toast({
        title: "Error",
        description: "Failed to create new case. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsCreatingCase(false);
      setIsNewCaseModalOpen(false);
      setNewCaseName('');
    }
  };

  const handleDateRangeChange = (range) => {
    setFilterOptions(prev => ({
      ...prev,
      created: {
        from: range?.from || null,
        to: range?.to || null
      }
    }));
  };

  const RenderAuditCard = ({ data }) => {
    return (
      <Card variant={'default'} my={4} py={4} px={8}>
        <Flex justify={'space-between'} align={'start'}>
          <Box>
            <HStack>
              <VStack justify={'start'} align={'self-start'}>
                <Text fontWeight={600} color={'black'} fontSize={'1.2rem'}>
                  {data.case_name}
                </Text>

                <Badge mb={2} variant={'case_status'}>
                  <HStack>
                    <InfoIcon height={'18px'} width={'18px'} fill="#000000" />
                    <Text fontSize={'0.85rem'} fontWeight={'bold'}>
                      {data.status?.text}
                    </Text>
                  </HStack>
                </Badge>
                <HStack spacing={12}>
                  <VStack minW={'100px'} spacing={1} justify={'start'} align={'self-start'}>
                    <Text variant={'label'}>Assignee</Text>
                    {(Object.keys(data.assigned_to).length > 0 && data.assigned_to?.id != "None") ? <HStack>
                      <Avatar size={'xs'} src={data.assigned_to.profile_picture_url} />
                      <Text color={'gray.800'}>
                        {data.assigned_to.full_name}
                      </Text>
                    </HStack> : <HStack>
                      <WarningIcon fill="var(--chakra-colors-yellow-500)" />
                      <Text color={'gray.800'}>
                        Unassigned
                      </Text>
                    </HStack>}
                  </VStack>
                  <VStack spacing={0} justify={'start'} align={'self-start'}>
                    <Text variant={'label'}>Created</Text>
                    <Text color={'gray.800'}>
                      {data.created_at && convertToRelativeTime(data.created_at)}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </HStack>
          </Box>
          <Flex align={'center'}>
            <Button onClick={() => handleNavigateToAnalysis(data._id)}>
              View Case
            </Button>
          </Flex>
        </Flex>
      </Card>
    );
  };

  return (
    <Flex justifyContent={'center'} alignItems={'center'}>
      <Box className="analysis-history-wrapper" maxW={'860px'} mt={16}>
        <Card px={8} py={4} >
          <Flex align={'center'} justifyContent="space-between" mb={4}>
            <Box width={'60%'}>
              <InputGroup>
                <InputLeftElement mt={2} ml={3} pointerEvents='none'>
                  <SearchIcon fill='#a8a8a8' />
                </InputLeftElement>
                <Input variant={'search'} placeholder='Search cases' />
              </InputGroup>
            </Box>
            <Button onClick={handleNewCase} variant={'secondary'} leftIcon={<AddIcon fill='#fff' />}>New Case</Button>
          </Flex>
          <Divider variant={'default'} />
          <Flex w={'100%'} mt={4} justify={'space-between'}>
            <SelectDropdown
              onChange={(value) => setSortOrder(value)}
              isMultiSelect={false}
              shouldShowAssignee={false}
              placeholderText='Sort by'
              shouldShowSearch={false}
              isClearable={false}
              Icon={SortIcon}
              options={[
                { id: 'desc', option: 'Newest First' },
                { id: 'asc', option: 'Oldest First' }
              ]}
            />
            <HStack>
              <SelectDropdown
                onChange={(value) => setFilterOptions(prev => ({ ...prev, assignee: value }))}
                isMultiSelect={true}
                shouldShowAssignee={true}
                placeholderText='Assignee'
                shouldShowSearch={true}
                Icon={ProfileIcon}
                options={assignees}
              />
              <SelectDropdown
                onChange={(value) => setFilterOptions(prev => ({ ...prev, status: value }))}
                isMultiSelect={false}
                shouldShowAssignee={false}
                placeholderText='Status'
                shouldShowSearch={true}
                Icon={InfoIcon}
                options={statusLabels}
              />

              <Popover>
                <PopoverTrigger>
                  <Button
                    variant={'dropdown_button'} leftIcon={<DateRange fill='#515151' />}>
                    Created Date
                  </Button>
                </PopoverTrigger>
                <PopoverContent width="auto" className="date-range-picker-wrapper">
                  <Box p={4}>
                    
                    <DayPicker
                    mode="range"
                    defaultMonth={new Date(new Date().getFullYear(), new Date().getMonth() - 1)}
                    selected={{
                      from: filterOptions.created.from,
                      to: filterOptions.created.to
                    }}
                    onSelect={handleDateRangeChange}
                    numberOfMonths={2}
                    pagedNavigation
                    showOutsideDays
                    disabled={{ after: new Date() }}
                    modifiers={{
                      range: {
                        from: filterOptions.created.from,
                        to: filterOptions.created.to
                      }
                    }}
                    modifiersStyles={{
                      range: { backgroundColor: '#f5f5f5' },
                      // rangeStart: { color: 'white', backgroundColor: '#00B870', borderRadius: '99px' },
                      // rangeEnd: { color: 'white', backgroundColor: '#00B870', borderRadius: '99px' }
                    }}

                    classNames={{
                      today: `day-today`,
                      selected: `${defaultClassNames.selected} day-selected`,
                      root: `${defaultClassNames.root} date-range-picker`,
                      day: `${defaultClassNames.day} day`,
                      rangeStart: `${defaultClassNames.rangeStart} day-range-start`,
                      rangeEnd: `${defaultClassNames.rangeEnd} day-range-end`,

                    }}
                    styles={{
                      months: { display: 'flex', flexDirection: 'row', gap: '1rem' },
                      month: { margin: 0 }
                    }}
                  />


                  </Box>
                </PopoverContent>
              </Popover>

            </HStack>
          </Flex>
        </Card>
        <Box mt={4}>
          <Text>Showing {pastAudits.length} of {pastAudits.length} cases</Text>
        </Box>
        {pastAudits.map(data => (
          <RenderAuditCard key={data.case_id} data={data} />
        ))}
      </Box>
      <Modal isOpen={isNewCaseModalOpen} onClose={() => setIsNewCaseModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody mt={5}>
            <FormControl>
              <FormLabel>Case Name</FormLabel>
              <Input
                variant={'default'}
                autoFocus={true}
                value={newCaseName}
                onChange={(e) => setNewCaseName(e.target.value)}
                placeholder="Enter case name"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={() => setIsNewCaseModalOpen(false)}>Cancel</Button>
            <Button isDisabled={!newCaseName?.length} variant="secondary" mr={3} onClick={handleCreateNewCase} isLoading={isCreatingCase}>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
export default AnalysisHistory;
