import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Select,
    VStack,
    HStack,
    Button,
    Text,
    Box,
    Card,
    CardBody,
    Divider,
    InputGroup,
    InputLeftAddon,
    IconButton,
    useToast
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import SelectDropdown from '../../components/SelectDropdown';

const NewTriageLabel = () => {
    const toast = useToast();
    const [labelName, setLabelName] = useState('');
    const [ruleGroups, setRuleGroups] = useState({
        operator: 'AND',
        groups: [{
            id: 1,
            rules: [
                {
                    id: 1,
                    field: '',
                    condition: '',
                    value: '',
                    upperValue: '',
                    operator: 'AND'
                }
            ]
        }]
    });

    const [matchingCases, setMatchingCases] = useState(0);
    const [fields, setFields] = useState([]);

    const addRuleGroup = () => {
        setRuleGroups(prev => ({
            ...prev,
            groups: [...prev.groups, {
                id: Date.now(),
                rules: [{
                    id: Date.now() + 1,
                    field: '',
                    condition: '',
                    value: '',
                    upperValue: '',
                    operator: 'AND'
                }]
            }]
        }));
    };

    const addRule = (groupId) => {
        setRuleGroups(prev => ({
            ...prev,
            groups: prev.groups.map(group => {
                if (group.id === groupId) {
                    return {
                        ...group,
                        rules: [...group.rules, {
                            id: Date.now(),
                            field: '',
                            condition: '',
                            value: '',
                            upperValue: '',
                            operator: 'AND'
                        }]
                    };
                }
                return group;
            })
        }));
    };

    const removeRule = (groupId, ruleId) => {
        setRuleGroups(prev => ({
            ...prev,
            groups: prev.groups.map(group => {
                if (group.id === groupId) {
                    return {
                        ...group,
                        rules: group.rules.filter(rule => rule.id !== ruleId)
                    };
                }
                return group;
            })
        }));
    };

    const removeRuleGroup = (groupId) => {
        setRuleGroups(prev => ({
            ...prev,
            groups: prev.groups.filter(group => group.id !== groupId)
        }));
    };

    const updateRule = (groupId, ruleId, field, value) => {
        setRuleGroups(prev => ({
            ...prev,
            groups: prev.groups.map(group => {
                if (group.id === groupId) {
                    return {
                        ...group,
                        rules: group.rules.map(rule => {
                            if (rule.id === ruleId) {
                                if (field === 'field') {
                                    return { 
                                        ...rule, 
                                        [field]: value,
                                        condition: '',
                                        value: '',
                                        upperValue: ''
                                    };
                                }
                                if (field === 'condition') {
                                    return {
                                        ...rule,
                                        [field]: value,
                                        value: '',
                                        upperValue: ''
                                    };
                                }
                                return { ...rule, [field]: value };
                            }
                            return rule;
                        })
                    };
                }
                return group;
            })
        }));
    };

    const updateTopLevelOperator = (operator) => {
        setRuleGroups(prev => ({
            ...prev,
            operator
        }));
    };

    const updateRuleOperator = (groupId, ruleId, operator) => {
        setRuleGroups(prev => ({
            ...prev,
            groups: prev.groups.map(group => {
                if (group.id === groupId) {
                    return {
                        ...group,
                        rules: group.rules.map(rule => {
                            if (rule.id === ruleId) {
                                return { ...rule, operator };
                            }
                            return rule;
                        })
                    };
                }
                return group;
            })
        }));
    };

    useEffect(() => {
        const calculateMatchingCases = () => {
            let count = 100;
            setMatchingCases(count);
        };
        calculateMatchingCases();
    }, [ruleGroups]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const dataToSubmit = {
            labelName,
            ruleGroups
        };
        
        try {
            // Save to API
            await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/save_triage_bucket`, dataToSubmit);
            
            // Copy to clipboard
            const jsonString = JSON.stringify(dataToSubmit, null, 2);
            await navigator.clipboard.writeText(jsonString);
            
            toast({
                title: "Success",
                description: "Label saved and copied to clipboard",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (err) {
            console.error('Error:', err);
            toast({
                title: "Error",
                description: "Failed to save label",
                status: "error", 
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchFieldData()
    }, [])

    const fetchFieldData = async () => {
        try {
            const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/list_fields`;
            const response = await axios.get(url);
            setFields(response.data);
        } catch (error) {
            console.error('Error fetching fields:', error);
        }
    };

    const getFieldOptions = (fieldId) => {
        const field = fields.find(f => f.id === fieldId);
        if (!field) return [];
        
        // For dynamic fields, return the values from API
        if (field.is_dynamic) {
            return field.values.map(value => ({
                id: value,
                option: value
            }));
        }
        
        // For static fields, return empty array as there are no recommended values
        return [];
    };

    const renderValueInput = (rule) => {
        const fieldsForValue = fields.find(f => f.id === rule.field);
        
        if (fieldsForValue?.type === 'amount') {
            if (rule.condition === 'between') {
                return (
                    <HStack spacing={2}>
                        <InputGroup>
                            <InputLeftAddon children="₹" />
                            <Input
                                type="number"
                                value={rule.value}
                                onChange={(e) => updateRule(rule.groupId, rule.id, 'value', e.target.value)}
                                placeholder="Lower threshold"
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputLeftAddon children="₹" />
                            <Input
                                type="number"
                                value={rule.upperValue}
                                onChange={(e) => updateRule(rule.groupId, rule.id, 'upperValue', e.target.value)}
                                placeholder="Upper threshold"
                            />
                        </InputGroup>
                    </HStack>
                );
            }
            return (
                <InputGroup>
                    <InputLeftAddon children="₹" />
                    <Input
                        type="number"
                        value={rule.value}
                        onChange={(e) => updateRule(rule.groupId, rule.id, 'value', e.target.value)}
                        placeholder="Amount"
                    />
                </InputGroup>
            );
        }

        // For dynamic fields, show dropdown with values
        if (fieldsForValue?.is_dynamic) {
            return (
                <SelectDropdown
                    options={getFieldOptions(rule.field)}
                    onChange={(value) => updateRule(rule.groupId, rule.id, 'value', value)}
                    selectedValue={getFieldOptions(rule.field).find(opt => opt.id === rule.value)}
                    placeholderText="Select value"
                    shouldShowAssignee={false}
                    isClearable={false}
                    shouldShowChevron={true}
                />
            );
        }

        // For static fields, show regular input
        return (
            <Input
                value={rule.value}
                onChange={(e) => updateRule(rule.groupId, rule.id, 'value', e.target.value)}
                placeholder="Enter value"
            />
        );
    };

    const fieldOptions = fields.map(field => ({
        id: field.id,
        option: field.field_name
    }));

    const operatorOptions = [
        { id: 'AND', option: 'AND' },
        { id: 'OR', option: 'OR' }
    ];

    const getConditionOptions = (fieldId) => {
        const field = fields.find(f => f.id === fieldId);
        if (!field) return [];

        switch (field.type) {
            case 'amount':
                return [
                    { id: 'greater', option: 'Greater than' },
                    { id: 'less', option: 'Less than' },
                    { id: 'between', option: 'Between' }
                ];
            case 'dynamic':
                return [
                    { id: 'equals', option: 'Equals' },
                    { id: 'not_equals', option: 'Does not equal' }
                ];
            default:
                return [];
        }
    };

    const renderRuleInputs = (rule, group, ruleIndex) => (
        <HStack spacing={4}>
            <SelectDropdown
                options={fieldOptions}
                onChange={(value) => updateRule(group.id, rule.id, 'field', value)}
                selectedValue={fieldOptions.find(opt => opt.id === rule.field)}
                placeholderText="Select field"
                shouldShowAssignee={false}
                isClearable={false}
                shouldShowChevron={true}
            />

            <SelectDropdown
                options={getConditionOptions(rule.field)}
                onChange={(value) => updateRule(group.id, rule.id, 'condition', value)}
                selectedValue={getConditionOptions(rule.field).find(opt => opt.id === rule.condition)}
                placeholderText="Condition"
                shouldShowAssignee={false}
                isClearable={false}
                shouldShowChevron={true}
            />

            {rule.field !== 'claim_value' ? (
                <SelectDropdown
                    options={getFieldOptions(rule.field)}
                    onChange={(value) => updateRule(group.id, rule.id, 'value', value)}
                    selectedValue={getFieldOptions(rule.field).find(opt => opt.id === rule.value)}
                    placeholderText="Select value"
                    shouldShowAssignee={false}
                    isClearable={false}
                    shouldShowChevron={true}
                />
            ) : (
                renderValueInput({...rule, groupId: group.id})
            )}

            <IconButton
                icon={<DeleteIcon />}
                onClick={() => removeRule(group.id, rule.id)}
                variant="ghost"
                colorScheme="red"
            />
        </HStack>
    );

    const renderOperator = (rule, group, ruleIndex) => (
        ruleIndex < group.rules.length - 1 && (
            <Box textAlign="center" my={2}>
                <SelectDropdown
                    options={operatorOptions}
                    onChange={(value) => updateRuleOperator(group.id, rule.id, value)}
                    selectedValue={operatorOptions.find(opt => opt.id === rule.operator)}
                    shouldShowAssignee={false}
                    isClearable={false}
                    shouldShowChevron={true}
                />
            </Box>
        )
    );

    return (
        <Container maxW="container.lg" py={8}>
            <VStack spacing={8} align="stretch">
                <Heading size="lg" fontFamily={'Garnett'} fontWeight={'600'}>Create New Triage Label</Heading>

                <Card variant="default">
                    <CardBody>
                        <form onSubmit={handleSubmit}>
                            <VStack spacing={6} align="stretch">
                                <FormControl isRequired>
                                    <FormLabel>Label Name</FormLabel>
                                    <Input
                                        value={labelName}
                                        onChange={(e) => setLabelName(e.target.value)}
                                        placeholder="Enter label name"
                                    />
                                </FormControl>

                                <VStack spacing={4} align="stretch">
                                    {ruleGroups.groups.map((group, groupIndex) => (
                                        <React.Fragment key={group.id}>
                                            <Box 
                                                p={4} 
                                                borderWidth={1} 
                                                borderRadius="md"
                                                borderColor="gray.200"
                                            >
                                                <VStack spacing={4} align="stretch">
                                                    <HStack justify="flex-end">
                                                        {groupIndex > 0 && (
                                                            <IconButton
                                                                icon={<DeleteIcon />}
                                                                onClick={() => removeRuleGroup(group.id)}
                                                                variant="ghost"
                                                                colorScheme="red"
                                                            />
                                                        )}
                                                    </HStack>

                                                    {group.rules.map((rule, ruleIndex) => (
                                                        <React.Fragment key={rule.id}>
                                                            {renderRuleInputs(rule, group, ruleIndex)}
                                                            {renderOperator(rule, group, ruleIndex)}
                                                        </React.Fragment>
                                                    ))}

                                                    <Button
                                                        leftIcon={<AddIcon />}
                                                        onClick={() => addRule(group.id)}
                                                        size="sm"
                                                        variant="ghost"
                                                    >
                                                        Add Rule
                                                    </Button>
                                                </VStack>
                                            </Box>

                                            {/* Show operator between groups, but not after the last group */}
                                            {groupIndex < ruleGroups.groups.length - 1 && ruleGroups.groups.length > 1 && (
                                                <Box textAlign="center" my={2}>
                                                    <SelectDropdown
                                                        options={operatorOptions}
                                                        onChange={(value) => updateTopLevelOperator(value)}
                                                        selectedValue={operatorOptions.find(opt => opt.id === ruleGroups.operator)}
                                                        shouldShowAssignee={false}
                                                        isClearable={false}
                                                        shouldShowChevron={true}
                                                    />
                                                </Box>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </VStack>

                                <Button
                                    leftIcon={<AddIcon />}
                                    onClick={addRuleGroup}
                                    variant="outline"
                                >
                                    Add Rule Group
                                </Button>

                                <Divider />

                                <Box p={4} bg="gray.50" borderRadius="md">
                                    <Text fontSize="lg" fontWeight="bold">
                                        Matching Cases: {matchingCases}
                                    </Text>
                                    <Text fontSize="sm" color="gray.600">
                                        Number of cases that match your current criteria
                                    </Text>
                                </Box>

                                <HStack justify="flex-end" spacing={4}>
                                    <Button variant="outline">Cancel</Button>
                                    <Button type="submit" variant="primary">
                                        Create Label
                                    </Button>
                                </HStack>
                            </VStack>
                        </form>
                    </CardBody>
                </Card>
            </VStack>
        </Container>
    );
};

export default NewTriageLabel;