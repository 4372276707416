import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Text, Button, VStack, HStack, Avatar } from '@chakra-ui/react';
import { ReactComponent as WarningIcon } from '../../images/warning.svg';


const DeleteMemberAlert = ({ isOpen, onClose, handleAction, cancelRef, member }) => {
    return (
        <AlertDialog
            size={'xl'}
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => onClose()}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader pb={2} fontSize="lg" fontWeight="bold">
                        Remove Member
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Box>
                            <Box>
                                <HStack bg={'red.50'} p={2} align={'center'} border={'1px solid'} borderColor={'red.400'} borderRadius={4} mb={2}>
                                    <WarningIcon fill='var(--chakra-colors-red-500)' />
                                    <Text color="red.500" fontWeight="bold">
                                        This action cannot be undone</Text>
                                </HStack>
                                <Text>This member will lose access to all data. The member's activity will be retained under a "Removed Member" profile.</Text>
                            </Box>

                            <Box border={'1px solid'} borderColor={'gray.200'} borderRadius={'md'} p={4} mt={4}>
                                <HStack spacing={4}>
                                    <Avatar size="md" src={member?.profile_picture_url} />
                                    <VStack align={'start'} spacing={0}>
                                        <Text color={'#000'} fontWeight="bold">
                                            {member?.full_name}
                                        </Text>
                                        <Text fontSize={'sm'} color={'gray.500'}>
                                            {member?.email}
                                        </Text>
                                        <Text fontSize={'sm'} color={'gray.500'} mt={1}>
                                            {member?.role?.name}
                                        </Text>
                                    </VStack>
                                </HStack>
                            </Box>
                        </Box>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={() => onClose()}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="red"
                            onClick={handleAction}
                            ml={3}
                        >
                            Remove Member
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default DeleteMemberAlert;