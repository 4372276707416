import React, { useState, useRef } from 'react';
import { Box, Button, VStack, Text, Input, HStack, useOutsideClick } from '@chakra-ui/react';
import { ReactComponent as CancelFilled } from '../../images/cancel_filled.svg';
import { ReactComponent as ChevronRight } from '../../images/chevron_right.svg';
import { motion } from 'framer-motion';

const DropdownWithCreate = ({ 
  options,
  onChange,
  selectedValue,
  placeholderText = "Search or create new...",
  onCreateNew,
  Icon
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const dropdownRef = useRef(null);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  React.useEffect(() => {
    if (selectedValue) {
      const option = options.find(opt => opt.id === selectedValue);
      setSelectedOption(option);
    }
  }, [selectedValue, options]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    setSearchTerm('');
    onChange(option.id);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    setSelectedOption(null);
    setSearchTerm('');
    onChange(null);
  };

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box position="relative" ref={dropdownRef}>
      <Button
        variant={'dropdown_button'}
        onClick={() => setIsOpen(!isOpen)}
        _hover={{ bg: 'gray.50' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        position="relative"
      >
        <HStack width="100%">
          {Icon && <Box height={'24px'} width={'24px'} mr={1}>
            <Icon fill={'#515151'} />
          </Box>}
          <Text color={'#515151'} fontWeight={'600'}>
            {selectedOption ? selectedOption.label : placeholderText}
          </Text>
          {selectedOption && (
            <Box
              as={motion.div}
              position="absolute"
              right={8}
              initial={{ opacity: 0 }}
              animate={{ opacity: isHovered ? 1 : 0 }}
              transition={{ duration: 0.1 }}
              onClick={handleClear}
              cursor="pointer"
            >
              <CancelFilled fill="#a8a8a8" height="16px" width="16px" />
            </Box>
          )}
          <Box
            as={motion.div}
            position="absolute"
            right={2}
            initial={{ rotate: 0 }}
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <ChevronRight 
              style={{ transform: 'rotate(90deg)' }} 
              fill="#a8a8a8" 
              height="24px" 
              width="24px" 
            />
          </Box>
        </HStack>
      </Button>

      {isOpen && (
        <VStack
          position="absolute"
          top="100%"
          left={0}
          width="250px"
          maxHeight="400px"
          overflowY="auto"
          bg="white"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          boxShadow="lg"
          zIndex={10}
          mt={1}
          spacing={0}
        >
          <Input
            variant={'default'}
            p={2}
            placeholder="Search..."
            autoFocus
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            m={2}
            width="90%"
          />
          
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <Box
                key={option.id}
                width="100%"
                px={3}
                py={3}
                cursor="pointer"
                _hover={{ bg: 'gray.50' }}
                onClick={() => handleSelect(option)}
              >
                <Text fontSize={'0.9rem'} color={'#515151'} fontWeight={'600'}>
                  {option.label}
                </Text>
              </Box>
            ))
          ) : (
            <Box
              width="100%"
              px={3}
              py={3}
              cursor="pointer"
              _hover={{ bg: 'gray.50' }}
              onClick={() => {
                onCreateNew(searchTerm);
                setIsOpen(false);
                setSearchTerm('');
              }}
            >
              <Text fontSize={'0.9rem'} color={'blue.500'} fontWeight={'600'}>
                Create "{searchTerm}"
              </Text>
            </Box>
          )}
        </VStack>
      )}
    </Box>
  );
};

export default DropdownWithCreate;
