import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Box, Text, Spinner, Flex } from '@chakra-ui/react';
import './index.scss';
import { setCookie } from '../../helpers/cookies';

import AdidemLogoFull from '../../images/adidem-logo-full.svg'

const JoinWorkspace = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const joinWorkspace = async (token) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_PATH}/join_workspace`, { token });
        if (response.status === 200) {
          const newToken = response.data.token;
          setCookie('auth-token', newToken, 1);
          axios.defaults.headers.common['auth-token'] = newToken;
          setMessage('You have been successfully joined to the workspace. Redirecting to dashboard...');
          setTimeout(() => {
            navigate('/case_list');
          }, 5000);
        }
      } catch (error) {
        setMessage('Failed to join the workspace. Please contact the admin.');
      } finally {
        setLoading(false);
      }
    };

    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      joinWorkspace(token);
    } else {
      setMessage('Invalid token. Please contact the admin.');
      setLoading(false);
    }
  }, [location, navigate]);

  return (
    <Flex className='join-workspace' height="100vh">
      <Box className="login-left-container" flex="1" bg="gray.100" display="flex" alignItems="center" justifyContent="center">
      </Box>
      <Box className='login-right-container' flex="1" display="flex" alignItems="center" justifyContent="center">
      <img className='adidem-logo-full' alt='Adidem Logo' src={AdidemLogoFull} />
        {loading ? <Spinner size="xl" /> : <Text fontSize={20}>{message}</Text>}
      </Box>
    </Flex>
  );
};

export default JoinWorkspace;
