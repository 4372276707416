import React, { useState, useEffect } from 'react';
import { Box, Divider, Collapse, useDisclosure } from '@chakra-ui/react';

const TimelineEventNode = ({ data, goToDoc }) => {
  const [tags, setTags] = useState([]);
  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    const { references } = data;
    const refsElements = references.map((ref, index) => (
      <div
        key={`ref-${index}`}
        className="reference-text"
        onClick={() => goToDoc(ref.doc_id, ref.statement)}
      >
        {ref.tags}
        <sup>{` [${index + 1}]`}</sup>
        {index < references.length - 1 ? ', ' : ''}
      </div>
    ));
    setTags(refsElements);
  }, [data]);

  return (
    <div className="event-node">
      <div className="date-box" style={{ maxWidth: '150px' }}>
        {data.date}
      </div>
      <div className="detail-box">
        <p>{data.description}</p>
        <Divider my={2} />
        <div className="references-toggle" onClick={onToggle}>
          {isOpen ? 'Collapse References' : 'View references'}
        </div>
        <Collapse in={isOpen} animateOpacity>
          <Box mt="4" rounded="md" shadow="md">
            {tags}
          </Box>
        </Collapse>
      </div>
    </div>
  );
};

const VisualTimeline = ({ timelineData, goToDoc }) => {

  return (
    <div className="timeline-container">
      <div className="timeline-content" style={{ width: '100%', height: '100%', position: 'relative', overflowY: 'auto' }}>
        {timelineData.map((event, index) => (
          <TimelineEventNode
            goToDoc={goToDoc}
            key={index}
            data={event}
          />
        ))}
      </div>
    </div>
  );
};

export default VisualTimeline;