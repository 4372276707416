import { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Card,
  CardBody,
} from '@chakra-ui/react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
  Area,
  AreaChart
} from 'recharts';

const Dashboard = () => {
  const [monthlyData] = useState([
    { name: 'Week 1', cases: 45 },
    { name: 'Week 2', cases: 52 },
    { name: 'Week 3', cases: 38 },
    { name: 'Week 4', cases: 63 }
  ]);

  const [categoryData] = useState([
    { name: 'Contract Disputes', value: 35 },
    { name: 'Employment', value: 25 },
    { name: 'Intellectual Property', value: 20 },
    { name: 'Corporate', value: 15 },
    { name: 'Real Estate', value: 5 }
  ]);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];
  
  return (
    <Container maxW="container.xl" py={8}>
      <Heading size="lg" mb={8} fontFamily={'Garnett'} fontWeight={'600'} color={'gray.900'}>
        Dashboard Overview
      </Heading>

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
      <Card variant={'default'}>
          <CardBody>
            <Heading size="md" mb={4} color="gray.800">Case Load in the Past Month</Heading>
            <Box height="400px">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={monthlyData}>
                  <defs>
                    <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#00B870" stopOpacity={0.2}/>
                      <stop offset="95%" stopColor="#00B870" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  {/* <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.06)" /> */}
                  <XAxis dataKey="name" stroke="#666" fontSize={12} tickLine={false} />
                  <YAxis stroke="#666" fontSize={12} tickLine={false} />
                  <Tooltip 
                    contentStyle={{
                      backgroundColor: '#fff',
                      border: '1px solid rgba(0,0,0,0.05)',
                      borderRadius: '8px',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
                    }}
                  />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="cases"
                    stroke="#00B870"
                    strokeWidth={2}
                    fill="url(#colorGradient)"
                    dot={false}
                    activeDot={{ r: 6, fill: '#00B870' }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </CardBody>
        </Card>

        <Card variant={'default'}>
          <CardBody>
            <Heading size="md" mb={4} color="gray.800">Cases by Category</Heading>
            <Box height="400px">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={categoryData}
                    cx="50%"
                    cy="50%"
                    labelLine={true}
                    label={({ name, percent, cx, cy, midAngle, outerRadius }) => {
                      const RADIAN = Math.PI / 180;
                      const radius = outerRadius + 20;
                      const x = cx + radius * Math.cos(-midAngle * RADIAN);
                      const y = cy + radius * Math.sin(-midAngle * RADIAN);
                      return (
                        <text
                          x={x}
                          y={y}
                          fill="#2D3748"
                          textAnchor={x > cx ? 'start' : 'end'}
                          dominantBaseline="central"
                        >
                          {`${name} (${(percent * 100).toFixed(0)}%)`}
                        </text>
                      );
                    }}
                    outerRadius={120}
                    innerRadius={80}
                    dataKey="value"
                    paddingAngle={2}
                  >
                    {categoryData.map((entry, index) => (
                      <Cell 
                        key={`cell-${index}`} 
                        fill={[
                          '#00B870',
                          '#4299E1',
                          '#F6AD55',
                          '#F56565',
                          '#9F7AEA',
                          '#ED64A6'
                        ][index % 6]}
                      />
                    ))}
                  </Pie>
                  <Tooltip 
                    contentStyle={{
                      backgroundColor: '#fff',
                      border: '1px solid rgba(0,0,0,0.05)',
                      borderRadius: '8px',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
                    }}
                  />
                  <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{ fontSize: '24px', fill: '#2D3748', fontWeight: 600 }}
                  >
                    {categoryData.reduce((sum, item) => sum + item.value, 0)}
                  </text>
                  <text
                    x="50%"
                    y="58%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{ fontSize: '14px', fill: '#718096' }}
                  >
                    Total Cases
                  </text>
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </CardBody>
        </Card>
      </SimpleGrid>
    </Container>
  );
};

export default Dashboard;
